.login-wrapper {
  max-height: 100vh;
}

.login-container {
  display: flex;
  width: 100%;
  padding: 0 !important;
  height: 100vh;
}

.left-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;

  position: relative;
}

.left-container .logo {
  max-width: 80%;
  margin: auto;
}

.right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  position: relative;
  background-color: #619a3f;
}

.right-container .form {
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem 0rem 2rem;
  max-width: 100%;
  margin: auto;
}

.right-container .submit {
  background-color: #d9e021 !important;
  color: #619a3f !important;
  border-color: #d9e021 !important;
}

.right-container .install {
  background-color: #ff9e1d !important;
  color: black !important;
  border-color: #d9e021 !important;
}

.right-container .submit:hover {
  background-color: #a7ac1d !important;
  color: white !important;
}
.right-container .install:hover {
  background-color: #c57916 !important;
  color: white !important;
}
.footer {
  display: flex;
  width: 100%;
  height: 30vh;
  padding: 0 !important;
}
.background {
  width: 100%;
  aspect-ratio: 3/0.8;
  height: auto;
  object-fit: fill;
}

.footer .background img {
  width: 100%;
  object-fit: fill;
}
@media (max-width: 576px) {
  .login-container {
    flex-direction: column;
  }
  .left-container {
    width: 100%;
    background-color: #619a3f;
    height: fit-content;
    padding: 2rem;

    align-items: flex-start;
  }

  .left-container .background {
    width: 100%;
    display: none;
  }

  .right-container {
    width: 100%;
    justify-content: flex-start;
    align-items: start;
    height: 100%;
    padding-bottom: 0;
  }

  .right-container .form {
    padding: 0rem 2rem 2rem 2rem;
    margin: unset;
  }
  .right-container .background {
    position: relative;
    margin-top: auto;
  }
}



.tab p::before {
  content: "";
  width: 100%;
  height: 2px;
  background-color: rgb(217, 224, 33);
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  transition: all 0.2s;
  scale: 0;
}

.tab p {
  position: relative;
}

.tab p:hover {
  color: #d9e021;
}
.tab p:hover::before {
  scale: 1;
}

.tab-active p {
  color: #d9e021;
}
.tab-active p::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: rgb(217, 224, 33);
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  transition: all 0.2s;
  scale: 1;
}

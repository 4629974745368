.chart-container {
  position: relative;
  margin: auto;
  height: 60vh;
  width: 100vw;
}

@media (min-width: 768px) {
  .chart-container {
    height: 400px;
    width: 100%;
  }
}
